"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetQuestionDetailQuery = exports.useFlaggedQuestionMutation = exports.useDeleteDiagnosticMutation = exports.useCreateDiagnosticMutation = exports.useParseMutation = exports.useQuizDetailQuery = exports.useDiagnosticDetailQuery = exports.useDiagnosticsQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const diagnosticApi = baseApiNew.injectEndpoints({
    endpoints: (builder) => ({
        diagnostics: builder.query({
            query: (id) => `/cms/common/diagnostics/test-type/${id}`,
        }),
        diagnosticDetail: builder.query({
            query: (id) => `/cms/common/diagnostics/${id}`,
        }),
        quizDetail: builder.query({
            query: (quizId) => `/diagnostic/${quizId}`,
        }),
        parse: builder.mutation({
            query: (file) => {
                const formData = new FormData();
                formData.append('pdf', file);
                console.log('FormData entries:', Array.from(formData.entries()));
                return {
                    url: `/diagnostic/parse`,
                    method: 'POST',
                    body: formData,
                    headers: {
                    // Add any custom headers if needed
                    },
                };
            },
        }),
        createDiagnostic: builder.mutation({
            query: (data) => {
                console.log('Incoming data for createDiagnostic:', data); // Log incoming data for debugging
                return {
                    url: `/diagnostic`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        deleteDiagnostic: builder.mutation({
            query: (quizId) => ({
                url: `/diagnostic/${quizId}`,
                method: 'DELETE',
            }),
        }),
        flaggedQuestion: builder.mutation({
            query: ({ body }) => ({
                url: `/diagnostic/flaggedQuestion`,
                method: 'POST',
                body: body,
            }),
        }),
        getQuestionDetail: builder.query({
            query: (qId) => `/diagnostic/getQuestionDetail/${qId}`,
        }),
    }),
    overrideExisting: false,
});
exports.default = diagnosticApi;
exports.useDiagnosticsQuery = diagnosticApi.useDiagnosticsQuery, exports.useDiagnosticDetailQuery = diagnosticApi.useDiagnosticDetailQuery, exports.useQuizDetailQuery = diagnosticApi.useQuizDetailQuery, exports.useParseMutation = diagnosticApi.useParseMutation, exports.useCreateDiagnosticMutation = diagnosticApi.useCreateDiagnosticMutation, exports.useDeleteDiagnosticMutation = diagnosticApi.useDeleteDiagnosticMutation, exports.useFlaggedQuestionMutation = diagnosticApi.useFlaggedQuestionMutation, exports.useGetQuestionDetailQuery = diagnosticApi.useGetQuestionDetailQuery;
